function envDevConfig() {
  var IS_PRODUCTION = false;
  var SERVICE_URL = "http://localhost:9095/api/"
  var BASE_URL = "http://localhost:8084/vyperbrandportalapi/";
  var IMG_URL = "https://edhiryalog.blob.core.windows.net/";
  var PURCHASE_INVOICE_SETTLEMENT_NOTIFY_EMAILS = ["ravisssndu@edhirya.com"];

  var KEYCLOAK_URL = "https://auth.vyper.cloud/auth/";
  var KEYCLOAK_REALM = "vyper";
  var KEYCLOAK_CLIENT = "web-client";

  var KEYCLOAK_ON_LOAD = "check-sso";
  var KEYCLOAK_CHECK_LOGIN_FRAME = false;

  var KEYCLOAK_ENABLE_BEARER_INTERCEPTOR = false;
  var KEYCLOAK_BEARER_EXCLUDED_URLS = ["/assets", "/clients/public"]

  var INTERCOM_APP_ID = "zg85lztd";

  this.isProduction = function () {
    return IS_PRODUCTION;
  }

  this.getServiceUrl = function () {
    return SERVICE_URL;
  }

  this.getBaseUrl = function () {
    return BASE_URL;
  }

  this.getImageUrl = function () {
    return IMG_URL;
  }

  this.getPurchaseInvoiceSettlementNotifyEmails = function () {
    return PURCHASE_INVOICE_SETTLEMENT_NOTIFY_EMAILS;
  }

  this.getKeycloakUrl = function () {
    return KEYCLOAK_URL;
  }

  this.getKeycloakRealm = function () {
    return KEYCLOAK_REALM;
  }

  this.getKeycloakClient = function () {
    return KEYCLOAK_CLIENT;
  }

  this.getKeycloakOnLoad = function () {
    return KEYCLOAK_ON_LOAD;
  }

  this.isKeycloakCheckLoginFrame = function () {
    return KEYCLOAK_CHECK_LOGIN_FRAME;
  }

  this.isKeycloakEnableBearerInterceptor = function () {
    return KEYCLOAK_ENABLE_BEARER_INTERCEPTOR;
  }

  this.getKeycloakExcludedUrls = function () {
    return KEYCLOAK_BEARER_EXCLUDED_URLS;
  }

  this.getIntercomAppId = function () {
    return INTERCOM_APP_ID;
  }
}
